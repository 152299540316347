import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutComponent} from '@layout/layout.component';
import {ListSolutionsComponent} from '@layout/solutions/list-solutions/list-solutions.component';
import {ProfileModule} from '@layout/profile/profile.module';
import {DetailsComponent} from '@layout/solutions/details/details.component';
import {SolutionsModule} from '@layout/solutions/solutions.module';
import {ExpertiseComponent} from '@layout/expertise/expertise.component';
import {UsersModule} from '@layout/users/users.module';
import {ListSolutionsAdminComponent} from '@layout/solutions/list-solutions-admin/list-solutions-admin.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'solutions'
      },
      {
        path: 'solutions',
        // loadChildren: () => import('./solutions/solutions.module').then(m =>SolutionsModule)
        loadChildren: './solutions/solutions.module#SolutionsModule'
      },
      {
        path: 'users',
        loadChildren:  './users/users.module#UsersModule'
      },
      {
        path: 'solutions_admin',
        component: ListSolutionsAdminComponent
      },
      {
        path: 'expertise',
        component: ExpertiseComponent
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
