import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MainStore} from '@store/mainStore.store';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss']
})
export class ExpertiseComponent implements OnInit {
  
  @ViewChild('section_1') section1: ElementRef;
  @ViewChild('section_2') section2: ElementRef;
  @ViewChild('section_3') section3: ElementRef;
  @ViewChild('section_4') section4: ElementRef;
  constructor(public mainStore: MainStore) {
    // this.mainStore.sidebarOpened = false;
    console.log('hello ExpertiseComponent', this.mainStore.sidebarOpened)
  }

  ngOnInit(): void {
  }


  scrollIntoView(section) {
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}
