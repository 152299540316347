import {Component, OnDestroy, OnInit} from '@angular/core';
import {SolutionService, UserService} from '@services/index';
import {Solution, User} from '@app/core/entities';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {$userRoles} from '@shared/Objects/sharedObjects';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-list-solutions-admin',
  templateUrl: './list-solutions-admin.component.html',
  styleUrls: ['./list-solutions-admin.component.scss']
})
export class ListSolutionsAdminComponent implements OnInit, OnDestroy {

  filter: any = {
    orders_by: [],
    keyword: '',
    page: 1,
    per_page: 10,
    filters: {
      tags: [],
      companies: []
    }
  };
  solutions: Solution[] = [];
  keyword = '';private
  searchSubscription: Subscription;
  loadingdata: boolean;
  loadError: boolean;
  $roles = $userRoles;

  constructor(private solutionService : SolutionService,
              private translate: TranslateService,
              private messageService: MessageService,
              private router: Router) { }

  ngOnInit() {
    this.getSolutions();
  }


  getSolutions(){
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.loadingdata = true;
    this.loadError = false;

    this.searchSubscription = this.solutionService.getSolutions({...this.filter}).subscribe(res => {
      this.solutions = res;
    }, err => {
      console.log('err', err);
    }, () => {
      console.log('finally');
      this.loadingdata = false;
    });
  }

  gotoAddSolution(){
    this.router.navigate(['/solutions/add']);
  }

  ngOnDestroy() {
    if(this.searchSubscription){ this.searchSubscription.unsubscribe(); }
  }



  async archive(id) {
    Swal.fire({
      title: this.translate.instant('ARE YOU SURE?'),
      text: this.translate.instant('ARE YOU SURE YOU WANT TO DELETE THIS PROJECT?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#59a6d4',
      cancelButtonColor: '#f3533b',
      confirmButtonText:  this.translate.instant('YES, DELETE!'),
      cancelButtonText: this.translate.instant('CANCEL'),
      heightAuto: false
    }).then(async (result) => {
      if (result.value) {
        try {
          const res = await this.solutionService.deleteProject({id}).toPromise();
          this.messageService.add({severity:'success', summary: 'SUCCESS',
            detail: this.translate.instant('PROJECT REMOVED SUCCESSFULLY'), sticky: false});
          this.getSolutions();
        }catch (e) {
          this.messageService.add({severity:'error', summary: 'FAILURE!',
            detail: this.translate.instant('FAILED TO DELETE PROJECT'), sticky: false});
        }finally {
        }
      }
    });
  }
}
