import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SolutionService, JwtService } from '@services/index';
import { Solution } from '@app/core/entities';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/Subscription';
import {ListsService} from '@services/lists.service';
import {$userRoles, appAnimations} from '@shared/Objects/sharedObjects';

@Component({
  selector: 'app-list-solutions',
  templateUrl: './list-solutions.component.html',
  styleUrls: ['./list-solutions.component.scss'],
  animations: appAnimations
})
export class ListSolutionsComponent implements OnInit{
  solutions: Solution[] = [];


  filter: any = {
    orders_by: [],
    keyword: '',
    page: 1,
    per_page: 10,
    filters: {
      tags: [],
      companies: []
    }
  };

  $userRoles = $userRoles;
  data = {
    tags: [],
    companies: [],
  };

  advancedFiltersLabels = {
    tags: 'TAGS',
    companies: 'COMPANIES'
  };
  private searchSubscription: Subscription;
  loadingdata: boolean;
  loadError: boolean;
  searchfilter = {
    tags: {
      keyword: ''
    },
    companies: {
      keyword: ''
    }
  };
    showFilters: boolean;


  constructor(private router : Router,
              private solutionService : SolutionService,
              private translate : TranslateService,
              private listsService : ListsService,
              private jwtService : JwtService) {}

  ngOnInit(): void {
    const loadAllFilters = new Promise((resolve, reject) => {

      Object.keys(this.filter.filters).forEach(itemfilter => {
        this.getFiltersLists(itemfilter);
        resolve();
      });
    });

    loadAllFilters.then(() => {
      this.getSolutions();
    });

    this.getSolutions();

  }

  gotoSolution(solution : Solution){
    
    let token = this.jwtService.getToken();

    if(solution.has_token){
      if(solution.token_cs_is_embedded == true)
        localStorage.setItem(solution.token_cs_object_name, JSON.stringify({token:token}));
      else
        localStorage.setItem('token', token);
    }

    window.open(solution.link_url, "_blank");

    this.track(solution.id)

  }

   getSolutions(){
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }
      this.loadingdata = true;
      this.loadError = false;

      this.searchSubscription = this.solutionService.getSolutions({...this.filter}).subscribe(res => {
        this.solutions = res;
      }, err => {
        console.log('err', err);
      }, () => {
          console.log('finally');
          this.loadingdata = false;
      });
  }

  track(solution_id){
    this.solutionService.navigate(solution_id).toPromise()
    .then((val) => {})
    .catch( err => {}) 
  }

  async getFiltersLists(itemfilter) {
    try {
      const list = await this.listsService.getAll(this.listsService.list[itemfilter], this.searchfilter[itemfilter]).toPromise();

      list.map(item => {
        item.checked = this.filter.filters[itemfilter].includes(item.id);
      });
      this.data[itemfilter] = list;
      console.log('this.data['+itemfilter+']=', this.data[itemfilter]);
    } catch (e) {
      console.log('e', e);
    } finally {

    }
  }

  resetFilters() {
    Object.keys(this.filter.filters).forEach(key => {
      this.filter.filters[key] = [];
    });
    this.getSolutions();
  }

  selectFilter(label, valueId){
    if( this.isSelected(label, valueId) ){
      this.filter.filters[label] = this.filter.filters[label].filter(el => el !== valueId);
    }else{
      this.filter.filters[label].push(valueId);
    }
    this.getSolutions();
  }

  isSelected(label, valueId){
    return this.filter.filters[label].find(el => el === valueId)
  }

    createSolution() {
        this.router.navigate(['/solutions/add']);
    }

    goEdit(id: Number | undefined) {
        this.router.navigate(['/solutions/'+id+ '/update']);
    }
}
