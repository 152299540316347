import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListSolutionsComponent } from './list-solutions/list-solutions.component';
import {DetailsComponent} from '@layout/solutions/details/details.component';
import {AddComponent} from '@layout/solutions/add/add.component';
import {UpdateComponent} from '@layout/solutions/update/update.component';
import {ListSolutionsAdminComponent} from '@layout/solutions/list-solutions-admin/list-solutions-admin.component';

const routes: Routes = [
  {
    path: '',
    component: ListSolutionsComponent
  },
  {
    path: 'add',
    component: AddComponent
  },
  {
    path: ':idSolution',
    component: DetailsComponent
  },
  {
    path: ':idSolution/update',
    component: UpdateComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SolutionsRoutingModule {}
