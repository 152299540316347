import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService, UserService} from '@app/core/services';
import {MessageService} from 'primeng/api';
import {markFormAsDirty, SharedClasses} from '@shared/Utils/SharedClasses';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompaniesService} from '@services/companies.service';

@Component({
  selector: 'app-add-company-modal',
  templateUrl: './add-company-modal.component.html',
  styleUrls: ['./add-company-modal.component.scss']
})
export class AddCompanyModalComponent implements OnInit {
  submitting: any;
  myForm: FormGroup;
   photoBinary: any;


  constructor(
      public userService: UserService,
      private fb: FormBuilder,
      private errorService: ErrorService,
      private messageService: MessageService,
      public modal: NgbActiveModal,
      private companiesService: CompaniesService,
  ) {
    this.myForm = this.fb.group({
      logo: [null, [Validators.required]],
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {

  }




  cancelSubmit() {
    this.modal.close('CANCEL');
  }

  async onSubmit() {
    console.log('this.myForm', this.myForm?.value);

    markFormAsDirty(this.myForm);
    if(!this.myForm.valid) return;

    const fd = new FormData()
    fd.append('name', this.myForm.value.name);
    fd.append('logo', this.myForm.value.logo);
    fd.append('description', this.myForm.value.description);

    try {
      this.submitting = true;
      const res = await this.companiesService.createCComapny(fd).toPromise();
      this.modal.close('QUERY');
    }catch (e){
    }finally {
      this.submitting = false;
    }
  }

  fileChanged(event){
    console.log('fileChanged', event);
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.myForm.patchValue({
        logo: file
      })
      let fr = new FileReader();
      fr.onload = (event:any)=>{
        this.photoBinary = event.target.result
      }
      fr.readAsDataURL(file)
    }

  }

  isRequired(control) {
    return SharedClasses.isControlRequired(control);
  }
}
