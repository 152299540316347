import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorService, SolutionService, UserService} from '@app/core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddCompanyModalComponent} from '@layout/companies/add-company-modal/add-company-modal.component';
import {CompaniesService} from '@services/companies.service';
import {SharedClasses} from '@shared/Utils/SharedClasses';
import {ListsService} from '@services/lists.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  myForm: any;
  companies = [];
  loadingCompanies: boolean;
  allTags = [];
  loadingTags: boolean;
  filteredTags: any[];
  typedTag = '';
  submitting: boolean;
  photoBinary;

  status = [
    {
      label: this.translate.instant('BEFORE'),
      value: 'before'
    },
    {
      label: this.translate.instant('AFTER WEB APP'),
      value: 'after'
    },
    {
      label: this.translate.instant('AFTER MOBILE APP'),
      value: 'after_mobile'
    },
  ];
   selectedCompany: any;

  constructor(private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private errorService: ErrorService,
              private messageService: MessageService,
              private router: Router,
              private location: Location,
              private companiesService: CompaniesService,
              private listsService: ListsService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private changeDetectorRef: ChangeDetectorRef,
              private solutionService : SolutionService) {

  }

  initFormBuilder(){
    const photo = {
      binaryPhoto: null,
      order: 1,
      file: null,
      status: 'before'
    }
    this.myForm = this.formBuilder.group({
      app_name: [null],
      logo: [null],
      description: [null],
      need_solution: [null],
      problem: [null],
      login: [null],
      password: [null],
      link_url: [null],
      note: [null],
      company_id: [null],
      tags: [[]],
      photos: this.formBuilder.array([...this.createItems( [photo])]),
    });
  }

  ngOnInit(): void {
    this.initFormBuilder();
    this.getCompanies();
    this.getTags();
  }


  businessLogoFileChanged(event) {
    // const modalRef = this.modalService.open(ImageCropperComponent);
    // modalRef.componentInstance.title = 'Business logo';
    // modalRef.componentInstance.maintainAspectRatio = false;
    // modalRef.componentInstance.file = event.target.files[0];
    // modalRef.componentInstance.submitImage.subscribe(({file, base64}) => {
    //   if (file instanceof File) {
        this.myForm.patchValue({
          logo: event.target.files[0]
        });
        // this.proLogoBinary = base64;
      // }
    // });
  }

  addCompany() {

    if(this.modalService.hasOpenModals()){
      return;
    }
    const modalRef = this.modalService.open(AddCompanyModalComponent, { size: 'lg' , centered: true, windowClass: 'myModal', backdrop: true});
    modalRef.result.then(result=>{
      console.log('closed modal', result);
      if(result === 'QUERY'){
        this.getCompanies();
      }
      // this.pointerElement?.nativeElement?.scrollIntoView();
    }, reason => {
      // console.log('closed hh', SharedClasses.getModalDissmissReason(reason));
    });
  }

  private async getCompanies() {
    try {
      this.loadingCompanies = true;
      const res = await this.companiesService.getCompanies({}).toPromise();
      this.companies = Array.isArray(res) ?  res : [];
    }catch (e){

    }finally {
      this.loadingCompanies = false;
    }
  }

  private async getTags() {
    try {
      this.loadingTags = true;
      const res = await this.listsService.getAll(this.listsService.list.tags, {}).toPromise();
      this.allTags = Array.isArray(res) ?  res.map(item=> {
        item.name = item.name.toLowerCase();
        return item;
      }) : [];
    }catch (e){

    }finally {
      this.loadingTags = false;
    }
  }

  isRequired(control) {
    return SharedClasses.isControlRequired(control);
  }

  filterTags(event) {
    let filtered : any[] = [];
    let query = event.query;

    if(query === '#'){
      this.filteredTags = this.allTags;
      return;
    }

    for(let i = 0; i < this.allTags.length; i++) {
      let tag = this.allTags[i];
      const tagName = tag.name?.indexOf('#') === 0 ? tag.name?.substring(1): tag.name;
      if (tagName.toLowerCase().indexOf(query?.toLowerCase()) == 0  ) {
        filtered.push(tag);
      }
    }

    this.filteredTags = filtered;
  }


  getTagLabel(tag) {
    if(!tag){
      return '';
    }
    return tag.indexOf('#') === 0 ? tag: '#'+tag;
  }

  selectItem($event) {
    console.log('$event', $event);

    if($event.id){
      let tag = $event.name.toLowerCase();

      if(tag && tag.length>0){
        tag = tag?.indexOf('#') === 0 ? tag.substring(1): tag;
        if(!this.myForm.value.tags.includes('#'+tag)){
          this.myForm.patchValue({
            tags: [...this.myForm.value.tags, tag]
          })
        }
      }

      this.typedTag = null;
    }

    if($event.type === 'keyup' && ($event.key === 'Enter')){
      let newTag = $event.target.value;
      if(newTag && newTag.length>0 ){
        newTag = newTag.toLowerCase().indexOf('#') === 0 ? newTag.toLowerCase().substring(1): newTag;
        if( !this.myForm.value.tags.includes('#'+newTag)){
          this.myForm.patchValue({
            tags: [...this.myForm.value.tags, newTag]
          })
        }
      }

      $event.target.value = null;
    }

  }

  removeTag(value) {
    this.myForm.patchValue({
      tags: this.myForm.value.tags.filter(tag => tag.toLowerCase() !== this.getTagLabel(value).toLowerCase() && tag.toLowerCase() !== value.toLowerCase())
    })
  }

  cancelSubmit() {
    this.router.navigate(['/solutions']);
  }

  async onSubmitAddProject() {
    if(!this.myForm.value) return;
    console.log('this.myForm.value', this.myForm.value);
    const fd = new FormData();
    fd.append('logo', this.myForm.value.logo);
    fd.append('app_name', this.myForm.value.app_name);
    fd.append('description', this.myForm.value.description);
    fd.append('need_solution', this.myForm.value.need_solution);
    fd.append('problem', this.myForm.value.problem);
    fd.append('login', this.myForm.value.login);
    fd.append('password', this.myForm.value.password);
    fd.append('link_url', this.myForm.value.link_url);
    fd.append('note', this.myForm.value.note);
    fd.append('company_id', this.myForm.value.company_id);

    for (let i=0; i<this.myForm.value.tags.length; i++){
      if(this.myForm.value.tags[i]?.indexOf('#') === 0){
        fd.append('tags['+i+']', this.myForm.value.tags[i]?.substring(1));
      }else{
        fd.append('tags['+i+']', this.myForm.value.tags[i]);
      }
    }

    for (let i=0; i<this.myForm.value.photos.length; i++){
      if(this.myForm.value.photos[i].binaryPhoto){
        fd.append('photos['+i+'][file]', this.myForm.value.photos[i]['file']);
        fd.append('photos['+i+'][order]', this.myForm.value.photos[i]['order']);
        fd.append('photos['+i+'][status]', this.myForm.value.photos[i]['status']);
      }
    }

    // console.log('sennding this', fd.get('photos'));
    // fd.forEach(el => console.log('fd el', fd));
    try {
      this.submitting = true;
      const res = await this.solutionService.createSolution(fd).toPromise();
      console.log('res', res);
      this.messageService.add({severity: 'success', summary: 'SUCCESS', detail: 'PROJECT CREATED SUCCESSFULLY', sticky: false});
    }catch (e){
      console.log('onSubmitAddProject', e)
    }finally {
      this.submitting = false;
    }


  }

  projectLogoFileChanged(event){
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.myForm.patchValue({
        logo: file
      })
      let fr = new FileReader();
      fr.onload = (event:any)=>{
        this.photoBinary = event.target.result
      }
      fr.readAsDataURL(file)
    }

  }

  drop(event: CdkDragDrop<string[]>) {

  }

  archive(i: number) {

  }

  addNewPhoto(event) {
    console.log('fileChanged', event);
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let fr = new FileReader();
      fr.onload = (event:any)=>{
        const newItem = {
          binaryPhoto: event.target.result,
          file: file,
          order: 1,
          status: 'before'
        }
        this.addPhoto(newItem);
      }
      fr.readAsDataURL(file)
    }

  }


  addPhoto(photo) {
    const array = this.myForm.get('photos') as FormArray;
    array.push(this.createItem(photo));
    this.changeDetectorRef.detectChanges();
  }

  removePhoto(index) {
    const array = this.myForm.get('photos') as FormArray;
    array.removeAt(index);
    this.changeDetectorRef.detectChanges();
  }

  private createItems(items): FormGroup[] {
    const arr = [];
    items.forEach(item => {
      arr.push(this.createItem(item));
    })
    return arr;
  }

  private createItem(item = null): FormGroup {
    return this.formBuilder.group({
      binaryPhoto: item ? item.binaryPhoto: null,
      file: item ? item.file : null,
      order: item ? item.order : null,
      status: item ? item.status : null,
    });
  }

  getPhotosControls(input: string) {
    return this.myForm.controls[input]['controls'];

  }

  getSelectedCompany() {
    return this.companies.find(company => company.id === this.myForm.value?.company_id);
  }

  selectCCompany() {
    this.selectedCompany = this.companies.find(company => company.id === this.myForm.value?.company_id);
  }

  goback() {
    this.location.back();
  }
}
