import { Component, OnInit } from '@angular/core';
import {SolutionService} from '@app/core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Solution} from '@app/core/entities';
import {environment} from '@env/environment';
import {MainStore} from '@store/mainStore.store';
import {toJS} from 'mobx';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {$userRoles} from '@shared/Objects/sharedObjects';
import {Location} from '@angular/common';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  loading: boolean;
  errorLoading: boolean;
  solution: Solution;
  photos_before = [];
  photos_after = [];
  photos_after_mobile = [];
  currentIndexBefore: number;
  showFlagBefore: boolean;
   showFlagAfter: boolean;
   showFlagAfterMobile: boolean;
   currentIndexAfter: number;
   currentIndexAfterMobile: number;
  $userRoles = $userRoles;
  constructor(private solutionService: SolutionService,
              private mainStore: MainStore,
              private router: Router,
              private messageService: MessageService,
              private translate: TranslateService,
              private location: Location,
              private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getSolution();
  }

  async getSolution(){
    try {
      this.loading = true;
      this.errorLoading = false;
      const params = {
        id: this.route.snapshot.params.idSolution
      }
      this.solution  = await this.solutionService.getOneSolution(params).toPromise();
      console.log('this.solution', this.solution);
      this.photos_before = [];
      this.photos_after = [];
      this.photos_after_mobile = [];
        this.solution.photos?.forEach(photo =>{
          if(photo.status === 'before') this.photos_before.push({
            thumbImage: photo.name,
            image: environment.photosBaseUrl+'solutions/'+this.solution.reduced_name+'/'+photo.name
          });
          if(photo.status === 'after')  this.photos_after.push({
            thumbImage: photo.name,
            image: environment.photosBaseUrl+'solutions/'+this.solution.reduced_name+'/'+photo.name
          });
          if(photo.status === 'after_mobile')  this.photos_after_mobile.push({
            thumbImage: photo.name,
            image: environment.photosBaseUrl+'solutions/'+this.solution.reduced_name+'/'+photo.name
          });
        })
    }catch (e){
      this.errorLoading = true;
    }finally {
      this.loading = false;
    }
  }

  closeEventHandler() {
    this.showFlagBefore = false;
    this.showFlagAfter = false;
    this.showFlagAfterMobile = false;
    this.currentIndexBefore = -1;
    this.currentIndexAfter = -1;
    this.currentIndexAfterMobile = -1;
  }

  showLightbox(_i: number) {
    this.currentIndexBefore = _i;
    this.currentIndexAfter = _i;
    this.currentIndexAfterMobile = _i;
    this.showFlagBefore = true;
  }


  goToTestPlateform() {
    const link = document.createElement('a');
    link.href = this.solution.link_url;
    link.target = '_blank';
    link.click()
  }


    goback() {
        this.location.back();
    }
}
