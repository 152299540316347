import {RouterModule, Routes} from '@angular/router';
import { AuthLayoutComponent } from './auth/layout/layout.component';
import {NgModule} from '@angular/core';
import {AuthGuard} from '@app/core/guards/auth-guard.service';
import {AuthGuard2} from '@app/core/guards/auth-guard-2.service';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    // component: AuthLayoutComponent,
    // loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    loadChildren: './auth/auth.module#AuthModule'// import('./auth/auth.module').then(m => m.AuthModule),
    // canActivate: [AuthGuard2]
  },
  {
    path: '',
    loadChildren: './layout/layout.module#LayoutModule',
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'app',
  //   redirectTo: '',
  //   canActivate: [AuthGuard]
  // },
  {
    path: '**',
    redirectTo: ''
  }
];


@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
