import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolutionsRoutingModule } from './solutions-routing.module';
import { ListSolutionsComponent } from './list-solutions/list-solutions.component';
import {SharedModule} from '@shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import { DetailsComponent } from './details/details.component';
import {NgImageFullscreenViewModule} from 'ng-image-fullscreen-view';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {AddComponent} from '@layout/solutions/add/add.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {AddCompanyModalComponent} from '@layout/companies/add-company-modal/add-company-modal.component';

import {AutoCompleteModule} from 'primeng/autocomplete';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {UpdateComponent} from '@layout/solutions/update/update.component';
import {ListSolutionsAdminComponent} from '@layout/solutions/list-solutions-admin/list-solutions-admin.component';
// import {TagInputModule} from 'ng x-chips';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
    imports: [
        CommonModule,
        SolutionsRoutingModule,
        SharedModule,
        NgbModule,
        FormsModule,
        TranslateModule,
        MatButtonModule,
        NgImageFullscreenViewModule,
        ClipboardModule,
        ReactiveFormsModule,
        NgSelectModule,
        AutoCompleteModule,
        DragDropModule,
        NgxPermissionsRestrictStubModule,
        // TagInputModule,
        // BrowserAnimationsModule,
    ],
  declarations: [
      ListSolutionsComponent,
      DetailsComponent,
      AddComponent,
      UpdateComponent,
      ListSolutionsAdminComponent
  ]
})
export class SolutionsModule { }
