import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

// component
import { AppComponent } from './app.component';
import { SolutionService, UserService, ApiService, JwtService, ErrorService } from './core/services';
import {APP_BASE_HREF, CommonModule, DatePipe} from '@angular/common';
import { environment } from 'environments/environment';
import {  PwdChangedGuard } from './core/guards';
import { HttpTokenInterceptor } from './core/interceptors';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthLayoutComponent } from './auth/layout/layout.component';
import {LayoutModule} from '@app/layout/layout.module';
import {SidebarModule} from 'ng-sidebar';
import {ToastModule} from 'primeng/toast';
// import {MobxAngularModule} from 'mobx-angular';
import {MessageService, SharedModule} from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {AuthGuard2} from '@app/core/guards/auth-guard-2.service';
import {MobxAngularModule} from 'mobx-angular';
import {MainStore} from '@store/mainStore.store';
import {UserStore} from '@store/user.store';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtStore} from '@store/jwt.store';
import {AppRoutingModule} from '@app/app-routing.module';
import {AuthGuard} from '@app/core/guards/auth-guard.service';
import {ListsService} from '@services/lists.service';
import {CompaniesService} from '@services/companies.service';



registerLocaleData(localeFr, 'fr-FR');

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
   return new TranslateHttpLoader(http, '../assets/i18n/', '.json?t='+new Date().getTime());
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        NgSelectModule,
        FormsModule,
        NgbModule,
        NgxPermissionsModule.forRoot(),
        SharedModule,
        AppRoutingModule,
        LayoutModule,
        SidebarModule,
        ToastModule,
        MobxAngularModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    [{provide: APP_BASE_HREF, useValue: environment.appBaseHref}],
    SolutionService,
    ListsService,
    CompaniesService,
    UserService,
    ErrorService,
    ApiService,
    JwtService,
    PwdChangedGuard,
    DatePipe,
    AuthGuard,
    AuthGuard2,
    MainStore,
    UserStore,
    JwtStore,
    MessageService
  ],
  declarations: [
    AppComponent,
    AuthLayoutComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
