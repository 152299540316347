export const environment = {
  production: true,
  apiUrl : 'https://demolab-api.piman.digital/api/',
  appBaseHref : './',
  logo: 'logo-pds.png',
  favicon: 'favicon.ico',
  title: 'PIMAN Group Solutions',
  backgroudImage: 'background.jpg',
  photosBaseUrl: 'https://demolab-api.piman.digital/api/images/',
  publicPhotosBaseUrl: 'https://demolab-api.piman.digital/storage/',
};
