import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import 'rxjs/add/operator/map';

@Injectable()
export class SolutionService {

  constructor(private apiService: ApiService) {}

  getSolutions(params): any {
    return this.apiService
      .post('solutions/list', params)
      .map(result => result.result.data  || []);
  }

  getOneSolution(params): any {
    return this.apiService
      .post('solutions/getOne', params)
      .map(result => result.result.data  || []);
  }

  connect(id): any {
    return this.apiService
      .post('solution/connect', {solution_id : id})
      .map(result => result.result.data);
  }

  navigate(id): any {
    return this.apiService
      .post('solution/navigate', {solution_id : id})
      .map(result => result.result.data);
  }

    createSolution(params) {
      return this.apiService
          .post('solution/create', params)
          .map(result => result.result.data);
    }

    updateSolution(params) {
      return this.apiService
          .post('solution/update', params)
          .map(result => result.result.data);
    }

    deleteProject(params) {
      return this.apiService
          .post('solution/delete', params)
          .map(result => result.result.data);
    }
}
