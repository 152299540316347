import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomeComponent } from './home/home.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import {ToastModule} from 'primeng/toast';
import {SharedModule} from '@shared/shared.module';
import {LayoutComponent} from '@layout/layout.component';
import {SidebarModule} from 'ng-sidebar';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {SolutionsModule} from '@layout/solutions/solutions.module';
import { ExpertiseComponent } from './expertise/expertise.component';
import {MatButtonModule} from '@angular/material/button';
import { AddCompanyModalComponent } from './companies/add-company-modal/add-company-modal.component';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [HeaderComponent,
        SidebarComponent,
        HomeComponent,
        ProjectDetailsComponent,
        LayoutComponent,
        ExpertiseComponent,
        AddCompanyModalComponent],
    exports: [
        HeaderComponent,
        SidebarComponent,
        AddCompanyModalComponent
    ],
    imports: [
        CommonModule,
        ToastModule,
        LayoutRoutingModule,
        SharedModule,
        SidebarModule,
        MatMenuModule,
        TranslateModule,
        SolutionsModule,
        MatButtonModule,
        ReactiveFormsModule
    ],
    entryComponents: [
        AddCompanyModalComponent
    ]
})
export class LayoutModule { }
